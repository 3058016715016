<template>
  <div class="flex flex-col relative">
    <div
      v-if="label || $slots.label"
      class="flex items-center justify-between"
    >
      <label
        :for="name"
        class="cursor-pointer mb-8 text-sm sm:text-base text-dark-cl-20"
      >
        <span v-if="!$slots.label">{{ label }}</span>
        <slot name="label" />
      </label>
      <div
        v-if="type === 'password'"
        class="flex text-sm sm:text-base items-center text-grey-fp-60 cursor-pointer mb-12"
        @click="handleShowPassword"
      >
        <AppIcon :name="showPassword ? 'eye-blocked' : 'eye'" />
        <span class="pl-5">{{ showPassword ? 'hide' : 'show' }}</span>
      </div>
    </div>

    <div class="relative w-full">
      <v-dropdown
        :triggers="[]"
        :shown="showPasswordHint"
        :autoHide="false"
        :disabled="!useHint"
        :offset="[0, 10]"
        :placement="screen.width < 1120 ? 'top' : 'right'"
      >
        <input
          v-bind="filteredAttrs"
          :id="name"
          v-maska="mask"
          :name="name"
          :type="inputType"
          :value="modelValue"
          :disabled="disabled"
          :class="[sizeStyles[size], errorStyles, {'opacity-30': disabled}]"
          :placeholder="placeholder"
          class="border rounded-5 focus:outline-none w-full text-dark-cl-20"
          :data-test="`input-${name}`"
          @input="onInputHandler"
          @blur="onBlur"
          @focus="onFocus"
        >
        <AppIcon
          :name="icon"
          size="25"
          class="absolute text-grey-fp-50 right-15 transform translate-y-1/2"
        />
        <template #popper>
          <div
            class="p-10"
            data-test="password-hint"
          >
            <p class="text-grey-fp-60 mb-5">
              Your password must be:
            </p>
            <ul class="text-grey-fp-60 ">
              <li
                v-for="(option, index) of hintOptions"
                :key="index"
                class="mb-5"
                data-test="hint-option"
                :class="{'text-success': option.matched }"
              >
                <AppIcon
                  name="check-circle"
                  class="mr-5"
                />
                <span>{{ option.message }}</span>
              </li>
            </ul>
          </div>
        </template>
      </v-dropdown>
    </div>

    <p
      v-if="err.message && !hideErrorMessage"
      :data-test="`input-${name}-error`"
      class="text-red-fp-30 absolute text-sm sm:text-base top-full left-0 mt-2 flex items-center whitespace-nowrap"
    >
      <span class="mr-3">{{ err.message }}</span>
      <router-link
        v-if="err.link"
        :to="{ name: err.link.name }"
      >
        <span class="underline">
          {{ err.link.message }}
        </span>
      </router-link>
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, toRefs, computed, reactive, PropType } from 'vue';
  import { maska } from 'maska';
  import { useScreen } from 'vue-screen';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IAppInputError } from '@/types';

  type TSizes = 'large' | 'medium';

  export default defineComponent({
    name: 'AppInput',

    directives: { maska },

    components: { AppIcon },

    props: {
      type: {
        type: String,
        default: 'text'
      },

      modelValue: {
        type: [String, Number],
        default: ''
      },

      name: {
        type: String,
        required: true
      },

      label: {
        type: String,
        default: ''
      },

      disabled: {
        type: Boolean,
        default: false
      },

      size: {
        type: String as PropType<TSizes>,
        default: 'medium'
      },

      error: {
        type: [String, Object as () => IAppInputError],
        default: ''
      },

      mask: {
        type: String,
        default: ''
      },

      icon: {
        type: String,
        default: ''
      },

      useHint: {
        type: Boolean,
        default: false
      },

      hintOptions: {
        type: Object,
        default: null
      },

      placeholder: {
        type: String,
        default: ''
      },

      hideErrorMessage: {
      type: Boolean,
      default: false
    }
    },

    emits: ['update:modelValue', 'focus', 'blur'],

    setup(props, { emit }) {
      const sizeStyles = { large: 'px-14 h-50', medium: 'h-40 px-15' } as const;

      const { type } = toRefs(props);

      let showPassword = ref<boolean>(false);
      let showPasswordHint = ref<boolean>(false);

      let inputType = computed<string>(() => (type.value === 'password' && showPassword.value) ? 'text' : type.value);
      let err = computed<IAppInputError>(() => {
        if (typeof props.error === 'string') {
          return reactive<IAppInputError>({ message: props.error, link: { name: '', message: '' } });
        }
        return props.error;
      });
      let errorStyles = computed<string>(() => err.value.message ? 'border-red-fp-30' : 'border-grey-fp-50 focus:border-primary');

      const screen = useScreen();

      function handleShowPassword () {

        showPassword.value = !showPassword.value;
      }

      function onInputHandler (e: InputEvent) {

        const target = e.target as HTMLInputElement;

        emit('update:modelValue', target.value);
      }

      function onBlur (e: InputEvent) {

        if (props.useHint) {
          showPasswordHint.value = false;
        }

        emit('blur', e);
      }

      function onFocus (e: InputEvent) {

        if (props.useHint) {
          showPasswordHint.value = true;
        }

        emit('focus', e);
      }

      return {
        showPassword,
        showPasswordHint,
        sizeStyles,

        inputType,
        errorStyles,
        screen,
        err,

        onInputHandler,
        handleShowPassword,
        onBlur,
        onFocus,
      };
    },

    computed: {
      filteredAttrs() {
        // TODO this is just workaround to remove binding classes
        let copyAttrs = { ...this.$attrs } as any;
        delete copyAttrs.class;

        return copyAttrs;
      }
    }
  });
</script>