
  import { defineComponent, ref, toRefs, computed, reactive, PropType } from 'vue';
  import { maska } from 'maska';
  import { useScreen } from 'vue-screen';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IAppInputError } from '@/types';

  type TSizes = 'large' | 'medium';

  export default defineComponent({
    name: 'AppInput',

    directives: { maska },

    components: { AppIcon },

    props: {
      type: {
        type: String,
        default: 'text'
      },

      modelValue: {
        type: [String, Number],
        default: ''
      },

      name: {
        type: String,
        required: true
      },

      label: {
        type: String,
        default: ''
      },

      disabled: {
        type: Boolean,
        default: false
      },

      size: {
        type: String as PropType<TSizes>,
        default: 'medium'
      },

      error: {
        type: [String, Object as () => IAppInputError],
        default: ''
      },

      mask: {
        type: String,
        default: ''
      },

      icon: {
        type: String,
        default: ''
      },

      useHint: {
        type: Boolean,
        default: false
      },

      hintOptions: {
        type: Object,
        default: null
      },

      placeholder: {
        type: String,
        default: ''
      },

      hideErrorMessage: {
      type: Boolean,
      default: false
    }
    },

    emits: ['update:modelValue', 'focus', 'blur'],

    setup(props, { emit }) {
      const sizeStyles = { large: 'px-14 h-50', medium: 'h-40 px-15' } as const;

      const { type } = toRefs(props);

      let showPassword = ref<boolean>(false);
      let showPasswordHint = ref<boolean>(false);

      let inputType = computed<string>(() => (type.value === 'password' && showPassword.value) ? 'text' : type.value);
      let err = computed<IAppInputError>(() => {
        if (typeof props.error === 'string') {
          return reactive<IAppInputError>({ message: props.error, link: { name: '', message: '' } });
        }
        return props.error;
      });
      let errorStyles = computed<string>(() => err.value.message ? 'border-red-fp-30' : 'border-grey-fp-50 focus:border-primary');

      const screen = useScreen();

      function handleShowPassword () {

        showPassword.value = !showPassword.value;
      }

      function onInputHandler (e: InputEvent) {

        const target = e.target as HTMLInputElement;

        emit('update:modelValue', target.value);
      }

      function onBlur (e: InputEvent) {

        if (props.useHint) {
          showPasswordHint.value = false;
        }

        emit('blur', e);
      }

      function onFocus (e: InputEvent) {

        if (props.useHint) {
          showPasswordHint.value = true;
        }

        emit('focus', e);
      }

      return {
        showPassword,
        showPasswordHint,
        sizeStyles,

        inputType,
        errorStyles,
        screen,
        err,

        onInputHandler,
        handleShowPassword,
        onBlur,
        onFocus,
      };
    },

    computed: {
      filteredAttrs() {
        // TODO this is just workaround to remove binding classes
        let copyAttrs = { ...this.$attrs } as any;
        delete copyAttrs.class;

        return copyAttrs;
      }
    }
  });
