<template>
  <button
    class="app-button flex justify-center items-center"
    :class="[
      customClass,
      size,
      { disabled, plain }, 
      type === 'secondary' && icon ? 'secondary-icon': type, 
    ]"
    :disabled="disabled"
  >
    <span 
      v-if="!$slots.icon && icon" 
      :class="iconClasses" 
      class="icon mr-12 flex text-md items-center justify-center p-5 rounded-half"
    >
      <AppIcon :name="icon" :size="iconSize" />
    </span>

    <!-- I C O N  S L O T -->
    <slot name="icon" />

    <!-- D E F A U L T  S L O T -->
    <span class="whitespace-nowrap">
      <slot />
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

type TTypes = 'primary' | 'secondary' | 'warning' | 'success' | 'error' | 'default' | 'text';
type TSizes = 'large' | 'medium' | 'small' | 'mini';

export default defineComponent({
  name: 'AppButton',

  components: { AppIcon },

  props: {
    customClass: {
      type: String,
      default: ''
    },

    type: {
      type: String as PropType<TTypes>,
      default: 'default'
    },

    plain: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: ''
    },

    iconClasses: {
      type: String,
      default: ''
    },

    iconSize: {
      type: String,
      default: '24'
    },
    
    size: {
      type: String as PropType<TSizes>,
      default: 'medium'
    },
  }
});
</script>


<style lang="scss" scoped>
  @import "@/assets/styles/scss/components/stateless/_app-button";
</style>

