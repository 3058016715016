
import { defineComponent, PropType } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

type TTypes = 'primary' | 'secondary' | 'warning' | 'success' | 'error' | 'default' | 'text';
type TSizes = 'large' | 'medium' | 'small' | 'mini';

export default defineComponent({
  name: 'AppButton',

  components: { AppIcon },

  props: {
    customClass: {
      type: String,
      default: ''
    },

    type: {
      type: String as PropType<TTypes>,
      default: 'default'
    },

    plain: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: ''
    },

    iconClasses: {
      type: String,
      default: ''
    },

    iconSize: {
      type: String,
      default: '24'
    },
    
    size: {
      type: String as PropType<TSizes>,
      default: 'medium'
    },
  }
});
